import { ref } from '@vue/composition-api'
import api from '../../services/api'

export default function () {
  const valores = ref([
    {
      titulo: 'Quantidade de mensagens',
      avatar: 'MessageSquareIcon',
      avatarVariant: 'light-primary',
      subTitulo: 'nos ultimos 60 dias',
      valor: 0,
    },
    {
      titulo: 'Lidas',
      avatar: 'BookOpenIcon',
      avatarVariant: 'light-success',
      subTitulo: 'nos ultimos 60 dias',
      valor: 0,
      query: 'Lidas',
    },
    {
      titulo: 'Não lidas',
      avatar: 'BookIcon',
      avatarVariant: 'light-warning',
      subTitulo: 'total',
      valor: 0,
      negativo: true,
      query: 'NaoLidas',
    },
    {
      titulo: 'Importantes não lidas',
      avatar: 'AlertCircleIcon',
      avatarVariant: 'light-danger',
      subTitulo: 'total',
      valor: 0,
      negativo: true,
      query: 'RelevanteNaoLido',
    },
    {
      titulo: 'Quantidade de e-Processos',
      avatar: 'MessageSquareIcon',
      avatarVariant: 'light-primary',
      subTitulo: 'nos ultimos 60 dias',
      valor: 0,
      negativo: true,
    },
    {
      titulo: 'e-Processos não lidas',
      avatar: 'BookIcon',
      avatarVariant: 'light-warning',
      subTitulo: 'nos ultimos 60 dias',
      valor: 0,
      negativo: true,
    },
    {
      titulo: 'Termos de exclusão',
      avatar: 'AlertCircleIcon',
      avatarVariant: 'light-danger',
      subTitulo: 'total',
      valor: 0,
      negativo: true,
    },
  ])

  const data60DiasAntes = new Date()
  data60DiasAntes.setDate(data60DiasAntes.getDate() - 60)

  api.getContadoresCaixaPostal()
    .then(payload => {
      valores.value = [
        {
          titulo: 'Quantidade de mensagens',
          avatar: 'MessageSquareIcon',
          avatarVariant: 'light-primary',
          subTitulo: 'nos ultimos 60 dias',
          valor: payload.data.totalMsg,
          dataInicio: data60DiasAntes.toLocaleDateString('pt-BR'),
        },
        {
          titulo: 'Lidas',
          avatar: 'BookOpenIcon',
          avatarVariant: 'light-success',
          subTitulo: 'nos ultimos 60 dias',
          valor: payload.data.totalLidas,
          dataInicio: data60DiasAntes.toLocaleDateString('pt-BR'),
          query: 'Lidas',
        },
        {
          titulo: 'Não lidas',
          avatar: 'BookIcon',
          avatarVariant: 'light-warning',
          subTitulo: 'total',
          valor: payload.data.totalNaoLidas,
          negativo: true,
          query: 'NaoLidas',
        },
        {
          titulo: 'Importantes não lidas',
          avatar: 'AlertCircleIcon',
          avatarVariant: 'light-danger',
          subTitulo: 'total',
          valor: payload.data.importantesNaoLidas,
          negativo: true,
          query: 'RelevanteNaoLido',
        },
        {
          titulo: 'Quantidade de e-Processos',
          avatar: 'MessageSquareIcon',
          avatarVariant: 'light-primary',
          subTitulo: 'nos ultimos 60 dias',
          valor: payload.data.totalEprocesso,
          negativo: true,
          query: 'EProcesso',
        },
        {
          titulo: 'e-Processos não lidas',
          avatar: 'BookIcon',
          avatarVariant: 'light-warning',
          subTitulo: 'nos ultimos 60 dias',
          valor: payload.data.totalEprocessoNaoLidas,
          negativo: true,
          query: 'EProcessoNaoLida',
        },
        {
          titulo: 'Termos de exclusão',
          avatar: 'AlertCircleIcon',
          avatarVariant: 'light-danger',
          subTitulo: 'total',
          valor: payload.data.totalTermoExclusao,
          negativo: true,
          query: 'TermoExclusao',
        },
      ]
    })

  return {
    totaisCaixaPostal: valores,
  }
}
